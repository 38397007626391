import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBottomSheet.figmaUrl.ios} codeUrl={checklists.componentBottomSheet.codeUrl.ios} checklists={checklists.componentBottomSheet.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The bottom sheet is a slides from the bottom of the screen Whereas a bottom navigation component is for buttons and specific application level actions, a bottom sheet can contain anything.`}</p>
    <p>{`Legion iOS UIKit offers five themes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ThemeAGRUIKit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ThemeEazyUIKit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ThemeIHSUIKit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ThemeLGNUIKit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ThemeMyTEnSUIKit`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`To import a theme, use the following syntax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGNUIKit
`}</code></pre>
    <h2>{`Header Options`}</h2>
    <h3>{`Header with title`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-title.png",
      "alt": "Header with title",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let bottomSheetVc = LGNBottomSheetUIKit(
    title: "Card Title"
)
presentBottomSheet(viewController: bottomSheetVc)
`}</code></pre>
    <h3>{`Header with title and left icon`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-title-left-icon.png",
      "alt": "Header with title and left icon",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let bottomSheetVc = LGNBottomSheetUIKit(
    title: "Card Title",
    leftIcon: UIImage(systemName: "chevron.left.circle.fill")
)
presentBottomSheet(viewController: bottomSheetVc)
`}</code></pre>
    <h3>{`Header with title, left icon and close button`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-title-left-icon-close-button.png",
      "alt": "Header with title, left icon and close button",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let bottomSheetVc = LGNBottomSheetUIKit(
    title: "Card Title",
    leftIcon: UIImage(systemName: "chevron.left.circle.fill"),
    showCloseButton: true
)
`}</code></pre>
    <h2>{`Body`}</h2>
    <p>{`Body property can be either:`}</p>
    <ul>
      <li parentName="ul">{`.text(String)`}</li>
      <li parentName="ul">{`.custom(UIView)`}</li>
    </ul>
    <h3>{`Body Text`}</h3>
    <p>{`Type `}<inlineCode parentName="p">{`.text(String)`}</inlineCode>{`: An optional body with input text string. If not provided, it defaults to nil.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-with-body-text.png",
      "alt": "Body Text",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let sampleText = "Mauris, turpis augue mauris tellus massa, lacus. Id quam adipiscing tincidunt at feugiat arcu in laore."

let bottomSheetVc = LGNBottomSheetUIKit(
    body: .text(sampleText)
)
presentBottomSheet(viewController: bottomSheetVc)

`}</code></pre>
    <h3>{`Body Custom`}</h3>
    <p>{`Type `}<inlineCode parentName="p">{`.custom(UIView)`}</inlineCode>{`: An optional body with any custom UIView. If not provided, it defaults to nil.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-with-body-custom.png",
      "alt": "Body Custom",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`func createSampleCustomView() -> UIView {
    let imageView = UIImageView()
    imageView.image = UIImage(systemName: "star.fill")
    imageView.tintColor = .systemBlue
    imageView.contentMode = .scaleAspectFit
    imageView.translatesAutoresizingMaskIntoConstraints = false
    imageView.heightAnchor.constraint(equalToConstant: 50).isActive = true
    imageView.widthAnchor.constraint(equalToConstant: 50).isActive = true

    let label = UILabel()
    label.text = "Mauris, turpis augue mauris tellus massa, lacus. Id quam adipiscing tincidunt at feugiat arcu in laore."
    label.numberOfLines = 0
    label.font = .systemFont(ofSize: 16)
    label.textColor = .darkGray
    label.translatesAutoresizingMaskIntoConstraints = false

    let button = LGNButton()
    button.label = "Submit"
    button.variant = .solid

    let stackView = UIStackView(arrangedSubviews: [imageView, label, button])
    stackView.axis = .vertical
    stackView.alignment = .center
    stackView.translatesAutoresizingMaskIntoConstraints = false

    let containerView = UIView()
    containerView.addSubview(stackView)

    NSLayoutConstraint.activate([
        stackView.topAnchor.constraint(equalTo: containerView.topAnchor),
        stackView.bottomAnchor.constraint(equalTo: containerView.bottomAnchor),
        stackView.leadingAnchor.constraint(equalTo: containerView.leadingAnchor),
        stackView.trailingAnchor.constraint(equalTo: containerView.trailingAnchor)
    ])

    return containerView
}

let customView = createSampleCustomView()

let bottomSheetVc = LGNBottomSheetUIKit(
    body: .custom(sampleText)
)
presentBottomSheet(viewController: bottomSheetVc)

`}</code></pre>
    <h2>{`Footer`}</h2>
    <p>{`Footer property can be one of the following:`}</p>
    <ul>
      <li parentName="ul">{`.single(LGNButton): An optional footer with a single LGNButton type. If not provided, it defaults to nil.`}</li>
      <li parentName="ul">{`.double(LGNButton, LGNButton): An optional footer with two LGNButton types that appear in a horizontal stack. If not provided, it defaults to nil.`}</li>
      <li parentName="ul">{`.stack(LGNButton, LGNButton): An optional footer with two LGNButton types that appear in a vertical stack. If not provided, it defaults to nil.`}</li>
      <li parentName="ul">{`.triple(LGNButton, LGNButton, LGNButton): An optional footer with three LGNButton types that appear in a horizontal stack. If not provided, it defaults to nil.`}</li>
    </ul>
    <h3>{`Footer Single`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-with-footer-single.png",
      "alt": "Footer Single",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button1 = LGNButton()
button1.label = "Button"
button1.variant = .solid

let bottomSheetVc = LGNBottomSheetUIKit(
    footer: .single(button1)
)
presentBottomSheet(viewController: bottomSheetVc)
`}</code></pre>
    <h3>{`Footer Double`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-with-footer-double.png",
      "alt": "Footer Double",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button1 = LGNButton()
button1.label = "Button"
button1.variant = .solid

let button2 = LGNButton()
button2.label = "Button"
button2.variant = .soft

let bottomSheetVc = LGNBottomSheetUIKit(
    footer: .double(button1, button2)
)
presentBottomSheet(viewController: bottomSheetVc)
`}</code></pre>
    <h3>{`Footer Stack`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-with-footer-stack.png",
      "alt": "Footer Stack",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button1 = LGNButton()
button1.label = "Button"
button1.variant = .solid

let button2 = LGNButton()
button2.label = "Button"
button2.variant = .soft

let bottomSheetVc = LGNBottomSheetUIKit(
    footer: .stack(button1, button2)
)
presentBottomSheet(viewController: bottomSheetVc)
`}</code></pre>
    <h3>{`Footer Triple`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-with-footer-triple.png",
      "alt": "Footer Triple",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button1 = LGNButton()
button1.label = "Button"
button1.variant = .transparent

let button2 = LGNButton()
button2.label = "Button"
button2.variant = .soft

let button3 = LGNButton()
button3.label = "Button"
button3.variant = .solid

let bottomSheetVc = LGNBottomSheetUIKit(
    footer: .triple(button1, button2, button3)
)
presentBottomSheet(viewController: bottomSheetVc)
`}</code></pre>
    <h2>{`Full Configuration`}</h2>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/ios-uikit/ios-uikit-bottomsheet-with-title-left-icon-close-button-body-text-footer-double.png",
      "alt": "Full Configuration",
      "width": 300,
      "style": {
        "display": "block",
        "margin": "0 auto"
      }
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button1 = LGNButton()
button1.label = "Button"
button1.variant = .solid

let button2 = LGNButton()
button2.label = "Button"
button2.variant = .soft

let sampleText = "Mauris, turpis augue mauris tellus massa, lacus. Id quam adipiscing tincidunt at feugiat arcu in laore."

let bottomSheetVc = LGNBottomSheetUIKit(
    title: "Card Title",
    leftIcon: UIImage(systemName: "chevron.left.circle.fill"),
    showCloseButton: true
    body: .text(sampleText)
    footer: .double(button1, button2)
)
presentBottomSheet(viewController: bottomSheetVc)
`}</code></pre>
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`body`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional body content, can be either `}<inlineCode parentName="td">{`.text(String)`}</inlineCode>{` or `}<inlineCode parentName="td">{`.custom(UIView)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`closeButton`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional close button displayed after the title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`footer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional footer content, can be `}<inlineCode parentName="td">{`.single(LGNButton)`}</inlineCode>{`, `}<inlineCode parentName="td">{`.double(LGNButton, LGNButton)`}</inlineCode>{`, `}<inlineCode parentName="td">{`.stack(LGNButton, LGNButton)`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.triple(LGNButton, LGNButton, LGNButton)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional icon displayed to the left of the title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`showOverlay(_:)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function to show or hide the overlay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`setIsDismissable(_:)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function to set if the bottom sheet is dismissable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional title for the bottom sheet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      